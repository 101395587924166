import { PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Input, Select, Space, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import * as API from "../../../api/API";
import logAction from "../../../utils/logActions";
import openNotification from "../../../utils/notification";
import AnalysisFeatureForm from "./AnalysisFeatureForm";
import FeatureForm from "./FeatureForm";

const { Option } = Select;

const defaultTemplates = [
  {
    _id: "ANALYSIS",
    name: "Modèle Sesha",
    type: "ANALYSIS",
    default: true,
    sesha: true,
    templateAnalysis: [
      {
        id: "ca",
        add_accounts: ["70"],
        remove_accounts: [],
        remove_journals: [],
        chart: "C",
        visible: true,
      },
      {
        id: "marge",
        // add_accounts: ["Chiffre d'affaire"], non modifiable
        remove_accounts: ["601", "602", "603", "604", "605", "607", "608", "609"],
        remove_journals: [],
        rule_1: true,
        chart: "L",
        visible: true,
      },
      {
        id: "masse_sal",
        add_accounts: ["64"],
        remove_accounts: [],
        remove_journals: [],
        chart: "L",
        visible: true,
      },
      {
        id: "treso",
        add_accounts: ["5"],
        remove_accounts: [],
        remove_journals: [],
        chart: "L",
        visible: true,
      },
      {
        id: "treso_dispo",
        add_accounts: ["512"],
        remove_accounts: [],
        remove_journals: [],
      },
      {
        id: "charges_ext",
        add_accounts: ["61", "62", "606"],
        remove_accounts: [],
        remove_journals: [],
        chart: "L",
        visible: true,
      },
      {
        id: "result",
        add_accounts: ["7"],
        remove_accounts: ["6"],
        remove_journals: [],
        chart: "L",
        visible: true,
      },
    ],
  },
  {
    _id: "MISSING_DOC",
    name: "Modèle Sesha",
    type: "MISSING_DOC",
    default: true,
    sesha: true,
    template: {
      add_accounts: ["40", "41", "47"],
      remove_accounts: ["404", "408", "416", "418", "419"],
      // add_journals: [], add journals pas dans le modèle mais directement au niveau du client
    },
  },
  {
    _id: "OUTSTANDINGS_CLIENTS",
    name: "Modèle Sesha",
    type: "OUTSTANDINGS_CLIENTS",
    default: true,
    sesha: true,
    template: {
      add_accounts: ["411"],
      remove_accounts: [],
      add_journals: ["VE", "VTE", "VT"],
    },
  },
  {
    _id: "OUTSTANDINGS_PROVIDERS",
    name: "Modèle Sesha",
    type: "OUTSTANDINGS_PROVIDERS",
    default: true,
    sesha: true,
    template: {
      add_accounts: ["401"],
      remove_accounts: [],
      add_journals: ["AC", "ACH"],
    },
  },
];

function FeatureSettings(props) {
  const [templates, setTemplates] = useState([]);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [reloadTemplates, setReloadTemplates] = useState(true);
  const [addItemWarningEmpty, setAddItemWarningEmpty] = useState(false);
  const [addItemWarningDuplicate, setAddItemWarningDuplicate] = useState(false);

  const onTemplateNameChange = (event) => {
    setNewTemplateName(event.target.value);
  };

  const addTemplate = (e) => {
    e.preventDefault();

    (async () => {
      if (templates.some((el) => el.name === newTemplateName)) {
        setAddItemWarningDuplicate(true);
      } else {
        setAddItemWarningDuplicate(false);
        const newTemplate = {
          name: newTemplateName,
          type: props.type,
          ...(props.type !== "ANALYSIS" && {
            template: defaultTemplates.find((elt) => elt.type === props.type).template,
          }),
          ...(props.type === "ANALYSIS" && {
            templateAnalysis: defaultTemplates.find((elt) => elt.type === props.type)
              .templateAnalysis,
          }),
        };
        if (newTemplateName !== "") {
          console.log(newTemplateName);
          setAddItemWarningEmpty(false);
          let res = await API.postAnalysisConfigTemplate(newTemplate);
          if (res.status === 201) {
            switch (props.type) {
              case "ANALYSIS":
                logAction(90, 1);
                break;
              case "MISSING_DOC":
                logAction(91, 1);
                break;
              case "OUTSTANDINGS_CLIENTS":
                logAction(92, 1);
                break;
              case "OUTSTANDINGS_PROVIDERS":
                logAction(93, 1);
                break;
              default:
                break;
            }
            res = await res.json();
            setTemplates([...templates, res]);
            setSelectedTemplate(res);
            setNewTemplateName("");
          } else {
            openNotification("error", "Erreur lors de la création du modèle");
          }
        } else {
          setAddItemWarningEmpty(true);
        }
      }
    })();
  };

  const defaultTemplate = (type, templates) => {
    let template = defaultTemplates.find((elt) => elt.type === type);
    return templates.some((el) => el.default) ? { ...template, default: false } : template;
  };

  useEffect(() => {
    (async () => {
      if (reloadTemplates) {
        let res = await API.getAnalysisConfigTemplates(); // get data
        res = await res.json();
        if (res.filter((elt) => elt.type === props.type).length === 0) {
          setTemplates([defaultTemplates.find((el) => el.type === props.type)]);
          setSelectedTemplate(defaultTemplates.find((el) => el.type === props.type));
        } else {
          const tmpTemplateList = [
            defaultTemplate(
              props.type,
              res.filter((elt) => elt.type === props.type)
            ),
            ...res.filter((elt) => elt.type === props.type),
          ];
          setTemplates(tmpTemplateList);
          setSelectedTemplate(
            selectedTemplate
              ? res
                  .filter((elt) => elt.type === props.type)
                  .find((el) => el._id === selectedTemplate._id)
              : tmpTemplateList.find((el) => el.default)
          );
        }

        setReloadTemplates(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, reloadTemplates]);

  const changeDefault = () => {
    (async () => {
      await API.putAnalysisConfigTemplate(templates.find((el) => el.default)?._id, {
        type: props.type,
        default: false,
      });
      await API.putAnalysisConfigTemplate(selectedTemplate._id, {
        type: props.type,
        default: true,
      });
      setSelectedTemplate({ ...selectedTemplate, default: true });

      let res = await API.getAnalysisConfigTemplates();
      res = await res.json();
      const tmpTemplateList = [
        defaultTemplate(
          props.type,
          res.filter((elt) => elt.type === props.type)
        ),
        ...res.filter((elt) => elt.type === props.type),
      ];
      setTemplates(tmpTemplateList);
    })();
  };

  return selectedTemplate && templates ? (
    <div className="type-settings">
      <b>Choisir un modèle :</b>
      <Select
        value={(selectedTemplate.default ? "(Défaut) " : "") + selectedTemplate.name}
        className="model-select"
        onSelect={(value) => {
          setSelectedTemplate(templates.find((elt) => elt._id === value));
        }}
        popupClassName="ant-select-dropdown-zindex"
        defaultValue={selectedTemplate?.name}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              align="center"
              style={{
                padding: "0 8px 4px",
              }}
            >
              <Input value={newTemplateName} onChange={onTemplateNameChange} />

              <Typography.Link
                onClick={addTemplate}
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <PlusOutlined /> Ajouter un modèle
              </Typography.Link>
            </Space>
            {addItemWarningEmpty ? (
              <span className="add-item-warning">
                Merci de renseigner un nom pour votre nouveau modèle.
              </span>
            ) : addItemWarningDuplicate ? (
              <span className="add-item-warning">Ce nom de modèle existe déjà</span>
            ) : null}
          </>
        )}
        placement="bottomLeft"
        dropdownAlign={{ overflow: { adjustY: false } }}
      >
        {templates.map((template, i) => {
          return (
            <Option value={template._id} key={i}>
              {(template.default ? "(Défaut) " : "") + template.name}
            </Option>
          );
        })}
      </Select>

      <Tooltip
        title={
          selectedTemplate.default
            ? "Ce modèle est déjà défini par défaut"
            : selectedTemplate.new
            ? "Vous devez enregistrer le modèle avant de pouvoir le définir par défaut"
            : null
        }
      >
        <Button
          className={
            selectedTemplate.new || selectedTemplate.default
              ? "template-default-btn-disabled"
              : "call-action-btn template-default-btn"
          }
          onClick={changeDefault}
          disabled={selectedTemplate.new || selectedTemplate.default}
        >
          Définir par défaut
        </Button>
      </Tooltip>

      {selectedTemplate.sesha ? (
        <Alert
          message="Le modèle Sesha ne peut pas être modifié, cependant, vous pouvez en créer d'autres afin de
          personnaliser vos analyses"
          type="info"
          showIcon
          className="alert-settings"
        />
      ) : null}

      {props.type !== "ANALYSIS" ? (
        //pour pm et encours
        <FeatureForm
          {...props}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          data={templates.filter((elt) => elt.type === props.type)}
          setReloadTemplates={setReloadTemplates}
          defaultTemplates={defaultTemplates}
        />
      ) : (
        <AnalysisFeatureForm
          {...props}
          selectedTemplate={selectedTemplate || []}
          setSelectedTemplate={setSelectedTemplate}
          setReloadTemplates={setReloadTemplates}
          defaultTemplates={defaultTemplates}
        />
      )}
    </div>
  ) : null;
}

export default FeatureSettings;
