import { DoubleLeftOutlined, FrownOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Popover, Result, Row, Skeleton } from "antd";
import * as dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as API from "../../../../api/API";
import { selectClient } from "../../../../slices/clientSlice";
import Loader from "../../../../utils/LoaderRaw";
import * as number from "../../../../utils/number";
import "../../MissingDocuments.css";
import Type from "./Type";

const views = {
  1: [
    { id: "enc", label: "Encaissements à justifier", data: [] },
    { id: "dec", label: "Décaissements à justifier", data: [] },
  ],
  2: [
    { id: "ach", label: "Achats", data: [] },
    { id: "ven", label: "Ventes", data: [] },
    { id: "att", label: "Compte d'attente et autres comptes", data: [] },
  ],
  3: [],
};

function MissingDocuments(props) {
  dayjs.locale("fr");
  const client = useSelector(selectClient);
  const clientId = props.match.params.clientId;

  const [filteredOp, setFilteredOp] = useState([]);
  const [nbrMissingDocuments, setNbrMissingDocuments] = useState(0);
  const [amountMissingDoc, setAmountMissingDoc] = useState(0);

  const [structuredOp, setStructuredOp] = useState();
  const [structuredAmount, setStructuredAmount] = useState();
  const [structuredNbrMissingDoc, setStructuredNbrMissingDoc] = useState();

  const [inactiveOperations, setInactiveOperations] = useState([]);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    console.debug("useEffect setMode Index.js");
    props.setMode(client.missingDocView || 1);
  }, [client.missingDocView]);

  useEffect(() => {
    if (!props.missingDocData) return;
    console.debug("useEffect missingDocData Index.js");

    let structuredOpTmp = JSON.parse(JSON.stringify(views[props.mode]));
    let filteredOpTmp = JSON.parse(JSON.stringify(views[props.mode]));
    const emptyDataTemplate = views[props.mode].map((el) => ({ ...el, data: null }));

    switch (props.mode) {
      case 1:
        const catByType = { 0: {}, 1: {} };
        props.missingDocData.operationCategories.forEach((account, j) => {
          account.operations.forEach((operation) => {
            const index = operation.amount > 0 ? 0 : 1;

            if (!catByType[index][j]) {
              catByType[index][j] = {
                ...account,
                operations: [],
              };
            }

            catByType[index][j].operations.push(operation);
            filteredOpTmp[index].data.push(operation);
          });
        });
        setStructuredAmount(JSON.parse(JSON.stringify(emptyDataTemplate)));
        setStructuredNbrMissingDoc(JSON.parse(JSON.stringify(emptyDataTemplate)));

        structuredOpTmp[0].data = Object.values(catByType[0]);
        structuredOpTmp[1].data = Object.values(catByType[1]);
        break;

      case 2:
        props.missingDocData.operationCategories.forEach((account) => {
          const index = account.accountNumber === "40" ? 0 : account.accountNumber === "41" ? 1 : 2;
          structuredOpTmp[index].data.push(account);
          filteredOpTmp[index].data.push(...account.operations);
        });
        setStructuredAmount(JSON.parse(JSON.stringify(emptyDataTemplate)));
        setStructuredNbrMissingDoc(JSON.parse(JSON.stringify(emptyDataTemplate)));
        break;

      case 3:
        const catByJournal = {};
        const opByJournal = {};

        props.missingDocData.operationCategories.forEach((account, j) => {
          account.operations.forEach((operation) => {
            if (!catByJournal[operation.journal]) {
              catByJournal[operation.journal] = {};
            }
            if (!catByJournal[operation.journal][j]) {
              catByJournal[operation.journal][j] = {
                title: account.title,
                accountNumberAux: account.accountNumberAux,
                operations: [],
              };
            }
            catByJournal[operation.journal][j].operations.push(operation);

            if (!opByJournal[operation.journal]) {
              opByJournal[operation.journal] = [];
            }
            opByJournal[operation.journal].push(operation);
          });
        });

        structuredOpTmp = Object.keys(catByJournal).map((journal) => ({
          id: journal,
          label: Object.values(catByJournal[journal])[0].operations[0].journalLabel,
          data: Object.keys(catByJournal[journal]).map((j) => ({
            _id: j + journal,
            accountNumberAux: catByJournal[journal][j].accountNumberAux,
            title: catByJournal[journal][j].title,
            operations: catByJournal[journal][j].operations,
          })),
        }));
        filteredOpTmp = Object.keys(opByJournal).map((journal) => ({
          id: journal,
          data: opByJournal[journal],
        }));
        setStructuredAmount(Object.keys(opByJournal).map((el) => ({ id: el, data: null })));
        setStructuredNbrMissingDoc(Object.keys(opByJournal).map((el) => ({ id: el, data: null })));
        break;
      default:
        break;
    }

    setStructuredOp(structuredOpTmp);
    setFilteredOp(filteredOpTmp);
  }, [props.missingDocData]);

  useEffect(() => {
    console.debug("useEffect structuredNbrMissingDoc and structuredAmount");
    if (!structuredNbrMissingDoc || !structuredAmount) return;
    if (
      !structuredNbrMissingDoc.some((obj) => obj.data !== null) ||
      !structuredAmount.some((obj) => obj.data !== null)
    )
      return;
    let totalAmount = 0;
    let nbrMissingDoc = 0;
    structuredNbrMissingDoc
      .filter((el) => props.mode !== 3 || props.journalListFiltered.includes(el.id))
      .filter((el) => el.data !== null)
      .forEach(({ data }) => {
        nbrMissingDoc += data;
      });
    structuredAmount
      .filter((el) => props.mode !== 3 || props.journalListFiltered.includes(el.id))
      .filter((el) => el.data !== null)
      .forEach(({ data }) => {
        totalAmount += Math.abs(data);
      });
    props.setMissingDocAmount(totalAmount);
    setAmountMissingDoc(totalAmount);
    setNbrMissingDocuments(nbrMissingDoc);
    (async () => {
      await API.putClient(clientId, {
        missingDocNumber: nbrMissingDoc + (client.otherMissingDocuments?.length || 0),
        missingDocAmount: totalAmount,
      });
    })();
  }, [structuredNbrMissingDoc, structuredAmount]);

  useEffect(() => {
    if (!structuredOp) return;
    // search unchecked operations and operations in unchecked accounts
    setInactiveOperations([
      ...structuredOp
        .flatMap((el) => el.data)
        .flatMap((el) => el.operations)
        .filter(({ active }) => active === false),
      ...structuredOp
        .flatMap((el) => el.data)
        .flatMap((compte) =>
          compte.operations.map((operation) => ({
            ...operation,
            accountNumberAux: compte.accountNumberAux,
          }))
        )
        .filter(
          (operation) =>
            operation.active === undefined &&
            client.uncheckedAccounts?.includes(
              operation.accountNumberAux + "-" + (operation.amount > 0 ? 2 : 1)
            )
        ),
    ]);
  }, [structuredOp]);

  function handleFinishMinAmount(value) {
    setStructuredOp((prev) => {
      return prev.map((el) => ({
        ...el,
        data: el.data.map((cat) => ({
          ...cat,
          operations: cat.operations.map((op) => ({
            ...op,
            active: Math.abs(op.amount) < value.minAmountSelect ? false : op.active,
          })),
        })),
      }));
    });
  }

  useEffect(() => {
    console.debug("useEffect set props Index.js");
    props.amount(number.parseNum(amountMissingDoc));
    props.nbr(nbrMissingDocuments);
    props.filteredOp(filteredOp);
    props.others(client.otherMissingDocuments);
    props.setBadgeMissing(nbrMissingDocuments + client.otherMissingDocuments?.length);
    props.setMissing(nbrMissingDocuments);
  }, [filteredOp, amountMissingDoc, nbrMissingDocuments, client.otherMissingDocuments]);

  const selectAll = (state) => {
    setStructuredOp((prev) => {
      return prev.map((el) => ({
        ...el,
        data: el.data.map((cat) => ({
          ...cat,
          operations: cat.operations.map((op) => ({ ...op, active: state })),
        })),
      }));
    });
  };

  return (
    <>
      {props.error ? (
        <Result
          icon={<FrownOutlined style={{ color: "#4569f8" }} />}
          title="Nous ne parvenons pas à recupérer les pièces manquantes"
        />
      ) : !props.missingDocData || !filteredOp ? (
        <Skeleton
          className="skeleton-analysis"
          active={true}
          paragraph={{
            rows: 4,
          }}
        />
      ) : (
        <>
          <Row style={{ marginBottom: "15px" }}>
            <Col span={editMode ? 10 : 0} className="minimum-amount">
              <Form
                name="basic"
                layout="inline"
                initialValues={{
                  remember: true,
                  minAmountSelect: "1",
                }}
                onFinish={handleFinishMinAmount}
              >
                <Form.Item name="minAmountSelect" label="Décocher les montants inférieurs à : ">
                  <InputNumber
                    min={1}
                    className="input-minimum-amount-button"
                    stringMode="true"
                    style={{ width: 80 }}
                    size="small"
                  />
                </Form.Item>
                <span style={{ position: "relative", right: "35px", top: "5px" }}>€</span>
                <Form.Item className="submit-min-amount-item">
                  <Button
                    size="small"
                    shape="round"
                    htmlType="submit"
                    className="submit-min-amount-btn"
                  >
                    Appliquer
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col span={editMode ? 6 : 0} className="select-all">
              <div className="switch-select center-content">
                <a
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    selectAll(false);
                  }}
                >
                  Tout désélectionner
                </a>
                <span> / </span>
                <a
                  href="# "
                  onClick={(e) => {
                    e.preventDefault();
                    selectAll(true);
                  }}
                >
                  Tout sélectionner
                </a>
              </div>
            </Col>
            <Col span={8} offset={editMode ? 0 : 16} style={{ textAlign: "right" }}>
              {inactiveOperations.length > 0 && (
                <span
                  style={{
                    color: "#9f9f9f",
                    fontSize: "13px",
                    marginRight: "15px",
                  }}
                >
                  {inactiveOperations.length} pièce{inactiveOperations.length > 1 ? "s" : ""} non
                  demandée{inactiveOperations.length > 1 ? "s" : ""}
                </span>
              )}

              <Popover
                placement="top"
                content={props.allExpanded ? "Tout replier" : "Tout déplier"}
                overlayClassName="navbar-popover-overlay"
              >
                <Button
                  className="expand-all-icon"
                  icon={
                    <DoubleLeftOutlined
                      className={props.allExpanded ? "expand-icon-down" : "expand-icon-up"}
                    />
                  }
                  shape="circle"
                  onClick={() => props.setAllExpanded(!props.allExpanded)}
                />
              </Popover>
              <Button
                className={editMode ? "call-action-btn" : "other-action-btn"}
                onClick={async (e) => {
                  e.preventDefault();
                  if (editMode) {
                    API.putClient(clientId, {
                      uncheckedAccounts: client.uncheckedAccounts,
                    });

                    let res = await API.postCheckedOperations(clientId, {
                      uncheckedOperations: filteredOp
                        .map((el) => el.data)
                        .flat(1)
                        .filter(({ active }) => active === false)
                        .map(({ _id }) => _id),
                    });
                    if (res.status === 200) {
                      res = await res.json();
                      setEditMode(!editMode);
                      props.setEditMode(!editMode);
                    } else {
                      console.error("Can't update unchecked operations");
                    }
                  } else {
                    setEditMode(!editMode);
                    props.setEditMode(!editMode);
                  }
                }}
              >
                {!editMode ? "Modifier la liste" : "Valider"}
              </Button>
            </Col>
          </Row>
          {props.loadingMissingDoc ? (
            <Loader />
          ) : (
            structuredOp &&
            structuredOp
              .filter((el) => props.mode !== 3 || props.journalListFiltered.indexOf(el.id) !== -1)
              .map((view) => (
                <Type
                  {...props}
                  key={view.id}
                  id={view.id}
                  mode={props.mode}
                  data={view.data}
                  allData={structuredOp}
                  setAllData={setStructuredOp}
                  label={view.label}
                  allExpanded={props.allExpanded}
                  setFilteredOp={setFilteredOp}
                  structuredNbrMissingDoc={structuredNbrMissingDoc}
                  setStructuredNbrMissingDoc={setStructuredNbrMissingDoc}
                  structuredAmount={structuredAmount}
                  setStructuredAmount={setStructuredAmount}
                  journalListFiltered={props.journalListFiltered}
                  startDate={props.startDate}
                  endDate={props.endDate}
                  editMode={editMode}
                />
              ))
          )}
        </>
      )}
    </>
  );
}

export default MissingDocuments;
